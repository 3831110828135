import splitDuration from "./splitDuration.js";

export default class Countdown extends HTMLElement {
    constructor() {
        super();
    }

    get value() {
        return Number(this.getAttribute("value"));
    }

    set value(v) {
        const n = Number(v);
        if (isNaN(n)) {
            return;
        }
        this.setAttribute("value", n);
    }

    connectedCallback() {
        const el = this;
        requestAnimationFrame(function handler() {
            if (!el.isConnected) {
                return;
            }
            el._handleCountdown(Date.now());
            requestAnimationFrame(handler);
        });
    }

    _handleCountdown(ref) {
        const value = this.value;

        if (isNaN(value)) {
            return;
        }
        
        const distribution = splitDuration(value - ref);
    
        ["chr-day", "chr-hour", "chr-min", "chr-sec"].forEach((name, i) => {
            this.querySelectorAll(name).forEach(v => v.textContent = distribution[i].toString());
        });
    }
}

customElements.define('chr-countdown', Countdown);
