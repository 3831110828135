export default (duration, steps = [24, 60, 60, 1000]) => {
    const divisions = steps.slice();
    const results = [];
    let remainder = duration;

    while (divisions.length) {
        const denom = divisions.reduce((v, curr) => curr * v, 1);
        results.push(Math.floor(remainder / denom));
        remainder = remainder % denom;
        divisions.shift();
    }

    results.push(remainder);

    return results;
}
